<template>
  <div class="m-1">
    <!-- 表格 -->
    <AreaLoading v-if="stocks.loading"></AreaLoading>
    <template v-else-if="stocks.data.length > 0">
      <div
        class="tw-container border border-1 border-dark p-0 mb-4"
        v-for="stock in stocks.data"
        :key="stock.id"
      >
        <div class="table-responsive p-0">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="text-start text-primary p-3 bg-white" colspan="10">
                  款式：
                  <span
                    v-for="style in stock.merchandiseStyles"
                    :key="style.id"
                  >
                    {{ style.name }}
                  </span>
                </th>
              </tr>
              <tr>
                <th style="min-width: 100px">單據編號</th>
                <th style="min-width: 120px">單據建立人員</th>
                <th style="min-width: 120px">單據建立時間</th>
                <th style="min-width: 95px">單據數量</th>
                <th style="min-width: 95px">成本</th>
                <th style="min-width: 80px">來源</th>
                <th style="min-width: 120px">入庫人員</th>
                <th style="min-width: 120px">入庫時間</th>
                <th style="min-width: 100px">已入庫數量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="history in stock.allStockHistories" :key="history.id">
                <td>{{ history.stockHistoryObject.receiptId }}</td>
                <td>{{ history.stockHistoryObject.receiptCreater }}</td>
                <td>{{ history.stockHistoryObject.receiptCreatedTime }}</td>
                <td>{{ history.stockHistoryObject.quantity }}</td>
                <td>{{ history.stockHistoryObject.cost }}</td>
                <td>{{ history.stockHistoryObject.source }}</td>
                <td>{{ history.stockHistoryObject.warehousingPersonal }}</td>
                <td>{{ history.stockHistoryObject.warehousingCreatedTime }}</td>
                <td>{{ history.stockHistoryObject.warehousingQty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <p class="fw-bolder alert alert-danger p-3 text-center" v-else>
      目前尚無庫存紀錄!
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // data
      serverToken: '',
      storeId: 0,
      merchandiseId: null,
      stocks: {
        loading: false,
        data: []
      }
    }
  },
  created() {
    this.initialization()
    this.getStockLists()
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 取得庫存列表
    getStockLists() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStockListsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      this.merchandiseId = this.$route.query.merchandiseId ? this.$route.query.merchandiseId : null
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getStocks{getMerchandiseStyles,getAllStockHistories{getSource{getPurchaseOrderHeader{getSupplier},getTopestBillHeader{getCreator{getUser}}},getParticipant{getUser}}}}'
        }
      ]
      this.stocks = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStockListsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const stocks = res.data[0].objects[0].stocks
            vm.stockMap(stocks)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理庫存列表
    stockMap(stocks) {
      stocks.forEach(item => {
        if (item.allStockHistories.length !== 0) {
          // 整理庫存紀錄資料
          item.allStockHistories.forEach(stockHistory => {
            if (stockHistory.sourceType === 'STOCK') {
              // 來源現貨
              stockHistory.stockHistoryObject = {
                receiptId: `${stockHistory.id}(現貨)`,
                receiptCreater: stockHistory.participant.user.name,
                receiptCreatedTime: this.$methods.moment(stockHistory.createTime).format("YYYY-MM-DD HH:mm:ss"),
                quantity: stockHistory.quantity,
                cost: item.merchandiseStyles[0].cost,
                source: '現貨',
                warehousingPersonal: stockHistory.participant.user.name,
                warehousingCreatedTime: this.$methods.moment(stockHistory.createTime).format("YYYY-MM-DD HH:mm:ss"),
                warehousingQty: stockHistory.quantity
              }
            } else if (stockHistory.sourceType === 'PURCHASEORDERBODY') {
              // 來源叫貨單
              stockHistory.stockHistoryObject = {
                receiptId: `${stockHistory.source.purchaseOrderHeaderId}(叫貨單)`,
                receiptCreater: stockHistory.participant.user.name,
                receiptCreatedTime: this.$methods.moment(stockHistory.source.purchaseOrderHeader.createTime).format("YYYY-MM-DD HH:mm:ss"),
                quantity: stockHistory.source.quantity,
                cost: item.merchandiseStyles[0].cost,
                source: stockHistory.source.purchaseOrderHeader.supplier.name,
                warehousingPersonal: stockHistory.participant.user.name,
                warehousingCreatedTime: this.$methods.moment(stockHistory.createTime).format("YYYY-MM-DD HH:mm:ss"),
                warehousingQty: stockHistory.quantity
              }
            } else if (stockHistory.sourceType === 'BILLBODY') {
              // 來源帳單
              stockHistory.stockHistoryObject = {
                receiptId: `${stockHistory.source.topestBillHeader.id}(帳單)`,
                receiptCreater: stockHistory.source.topestBillHeader.creator.user.name,
                receiptCreatedTime: this.$methods.moment(stockHistory.source.topestBillHeader.createTime).format("YYYY-MM-DD HH:mm:ss"),
                quantity: stockHistory.source.quantity,
                cost: item.merchandiseStyles[0].cost,
                source: stockHistory.quantity > 0 ? "退貨" : "寄出",
                warehousingPersonal: stockHistory.participant.user.name,
                warehousingCreatedTime: this.$methods.moment(stockHistory.createTime).format("YYYY-MM-DD HH:mm:ss"),
                warehousingQty: stockHistory.quantity
              }
            }
          })
          this.stocks.data.push(item)       
        }        
      })
      this.stocks.loading = false   
    }
  }
}
</script>